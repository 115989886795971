define("ember-local-storage/initializers/local-storage-adapter", ["exports", "ember-data", "ember-local-storage/helpers/import-export"], function (_exports, _emberData, _importExport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    if (!_emberData.default.Store.prototype._emberLocalStoragePatched) {
      _emberData.default.Store.reopen({
        _emberLocalStoragePatched: true,
        importData: function (json, options) {
          return (0, _importExport.importData)(this, json, options);
        },
        exportData: function (types, options) {
          return (0, _importExport.exportData)(this, types, options);
        }
      });
    }
  }

  var _default = {
    name: 'local-storage-adapter',
    after: 'ember-data',
    initialize: initialize
  };
  _exports.default = _default;
});